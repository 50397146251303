/* Google Font CDN Link */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter" , sans-serif;
}
:root{
    --black-color: #222222;
    --white-color: #fff;
    --sky-color: #E4E9F7;
    --light-black: #1d1b31;
    --light-orange: #eabd87;
    --light-orange-hover: #c09c71;

    /* Alerts */
    --primary: #0676ed;
    --background: #222b45;
    --warning: #f2a600;
    --success: #12c99b;
    --error: #e41749;
    --dark: #151a30;
}
a, p, div, button{
    font-size: 14px;
}
ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
}
h1, h2, h3, h4, h5, h6{
    color: #505458;
    font-family: 'Inter';
}
.mt-0 {
    margin-top: 0px !important;
}

/* Sections */
.section-wrapper{
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}


/* Custom Styles */
.main-section-body {
    width: 100%;
    background-image: linear-gradient(#000000,#000000);
    background-size: auto,cover;
    background-color: #272727;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    background-blend-mode: multiply;
}
.video-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.video-container video {
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.video-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.main-section-body .video-overlay {
    background-color: rgb(0 0 0 / 75%);
}

/* Header */
section.main-header {
    position: absolute;
    z-index: 10;
    width: 100%;
}
.heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    max-width: 1900px;
}
.logo-main img {
    width: 210px;
}
.bg-logo-overlay {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.02;
    position: absolute;
    left: 0;
}
.company-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 215.57px;
}
.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
    position: relative;
    z-index: 1;
}
.company-logo .logo-main {
    margin-left: 10px;
}
.logo-back-btn {
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: .2s;
    color: #fff !important;
    cursor: pointer;
}
.connect-wallet .connect-btn {
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid #FFFFFF;
    box-shadow: -7px 35px 25px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 205px;
    height: 51px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .2s;
}
.connect-wallet .connect-btn:hover,
.logo-back-btn:hover{
    background: rgb(255 255 255 / 14%);
}

/* Minting Section */
.minting-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
}
.minting-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -105px;
}
.minting-container {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 20px;
    padding: 40px 33px;
    margin-top: 240px;
}
.minting-heading img {
    width: 401px;
    margin-bottom: 15px;
}
.minting-heading h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.minting-heading h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.mint-button-big .min-btn-img {
    background: #9C590A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
}
.min-btn-img img {
    width: 90px;
    position: relative;
    left: -4px;
}
.min-btn-border {
    width: 111px;
    height: 111px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
    transition: .2s;
}
.mint-button-big {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 256px;
    transition: .2s;
    margin-left: 11px;
    padding: 20px;
}
.mint-button-big:hover {
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    background: #9C590A;
}
.mint-button-big:hover .min-btn-border {
    background: rgb(255 255 255 / 8%);
}
.minting-area.minting-area-details {
    width: 417px;
    background: rgb(0 0 0 / 20%);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    padding: 14px;
    margin-right: 11px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.minting-area-details .mint-desc {
    width: 180px;
    color: #000000;
    padding: 6px 20px;
    background: #FFFFFF;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
}
.minting-area-details .mint-desc ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    text-align: right;
    color: #000000;
}
.mint-fields ul li span {
    opacity: .3;
}
.minting-area-content {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;
}
.mint-fields {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.mint-fields ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}
.mint-fields select.form-field {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 39px;
    color: #FFFFFF;
    background: transparent;
    padding: 0 13px;
    outline: 0;
}
.mint-fields select.form-field * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    background-color: #16120a;
}
.mint-fields select{
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}
.mint-fields select::-ms-expand {
    display: none;
}
.contract-address {
    position: relative;
    margin-top: 40px;
    margin-bottom: 60px;
}
.contract-address h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}
.contract-address h4 a{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 8px;
    display: block;
    transition: .2s;
}
.contract-address h4 a:hover {
    color: #9C590A;
}
ul.social-icons li {
    display: flex;
    align-items: center;
    justify-content: center;
}
ul.social-icons li a{
    margin: 10px;
}
ul.social-icons li a.opensea-icon svg {
    color: #fff;
    width: 35px;
    transition: .2s;
}
ul.social-icons li a.telegram-icon {
    font-size: 35px;
    color: #fff;
    transition: .2s;
}
ul.social-icons li a.twitter-icon,
ul.social-icons li a.mail-icon,
ul.social-icons li a.discord-icon {
    color: #212020;
    background: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .2s;
    font-size: 18px;
}
ul.social-icons li a.opensea-icon:hover svg{
    color: rgb(197 197 197);
}
ul.social-icons li a.telegram-icon:hover{
    color: rgb(197 197 197);
}
ul.social-icons li a.twitter-icon:hover,
ul.social-icons li a.mail-icon:hover,
ul.social-icons li a.discord-icon:hover {
    background: rgb(197 197 197);
}
.htp-link button {
    font-size: 17px;
    margin-top: 20px;
    display: block;
    text-align: center;
    line-height: 1;
    margin-bottom: -10px;
    text-transform: uppercase;
    color: #ff910f;
    transition: .2s;
    border: 0;
    width: 100%;
}
.htp-link button:hover{
    color: #9C590A;
}

/* Modal Close Button */
.modalCloseBtn {
    cursor: pointer;
    transition: .2s;
    font-size: 18px;
    line-height: 1;
    background: rgb(255 255 255 / 4%);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalCloseBtn:hover {
    background: rgb(255 255 255 / 15%);
}
/* Connect Wallet Modal */

.connectWalletModal .modal-dialog {
    max-width: 850px !important;
    width: 100%;
}
.connectWalletModal .modal-content {
    color: #ffffff;
    background-image: linear-gradient(rgb(0 0 0 / 75%), rgb(0 0 0 / 75%)), url(../../../src/assets/img/colored-bg.png);
    background-size: cover;
    background-position: center;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    border: 2px solid #ffffff8c;
}
.connectWalletModal .modal-content .modal-header {
    border: 0;
}
.connectWalletModal .modal-title {
    margin-bottom: 0;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
}
.conn-button-big{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 256px;
    transition: .2s;
    padding: 20px;
}
.conn-button-big:hover {
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
}
.conn-button-big.walletConnectBtn:hover {
    background: #217ffc;
}
.conn-button-big.coinbaseWalletBtn:hover {
    background: #1652f0;
}
.conn-button-big.metamaskIconBtn:hover {
    background: #763e1a;
}
.conn-btn-border {
    width: 111px;
    height: 111px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
    transition: .2s;
}
.conn-button-big .conn-btn-img {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
}
.conn-btn-img img {
    width: 90px;
    position: relative;
}
.connectWalletWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* How To Purchase Modal */
.howToPurchaseModal .modal-dialog {
    max-width: 1000px !important;
    width: 100%;
}
.howToPurchaseModal .modal-content {
    color: #ffffff;
    background-image: linear-gradient(rgb(0 0 0 / 75%), rgb(0 0 0 / 75%)), url(../../../src/assets/img/colored-bg.png);
    background-size: cover;
    background-position: center;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    border: 2px solid #ffffff8c;
}
.howToPurchaseModal .modal-content .modal-header {
    border: 0;
}
.howToPurchaseModal .modal-title {
    margin-bottom: 0;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
}
.video-content-details {
    padding-top: 30px;
    text-align: center;
}
.video-content-details h2 {
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}
/* Video Content Style */
.video-content-wrapper .video-content{
    position: relative;
    padding-bottom: 62.5%;
    height: 0;
}
.video-content-wrapper .video-content iframe{
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}