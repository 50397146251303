@import url(https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap);
/* Google Font CDN Link */
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter" , sans-serif;
}
:root{
    --black-color: #222222;
    --white-color: #fff;
    --sky-color: #E4E9F7;
    --light-black: #1d1b31;
    --light-orange: #eabd87;
    --light-orange-hover: #c09c71;

    /* Alerts */
    --primary: #0676ed;
    --background: #222b45;
    --warning: #f2a600;
    --success: #12c99b;
    --error: #e41749;
    --dark: #151a30;
}
a, p, div, button{
    font-size: 14px;
}
ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
}
h1, h2, h3, h4, h5, h6{
    color: #505458;
    font-family: 'Inter';
}
.mt-0 {
    margin-top: 0px !important;
}

/* Sections */
.section-wrapper{
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 20px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
}


/* Custom Styles */
.main-section-body {
    width: 100%;
    background-image: linear-gradient(#000000,#000000);
    background-size: auto,cover;
    background-color: #272727;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    background-blend-mode: multiply;
}
.video-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
.video-container video {
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}
.video-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.main-section-body .video-overlay {
    background-color: rgb(0 0 0 / 75%);
}

/* Header */
section.main-header {
    position: absolute;
    z-index: 10;
    width: 100%;
}
.heading-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    max-width: 1900px;
}
.logo-main img {
    width: 210px;
}
.bg-logo-overlay {
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.02;
    position: absolute;
    left: 0;
}
.company-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 215.57px;
}
.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
    position: relative;
    z-index: 1;
}
.company-logo .logo-main {
    margin-left: 10px;
}
.logo-back-btn {
    border: 2px solid #FFFFFF;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    transition: .2s;
    color: #fff !important;
    cursor: pointer;
}
.connect-wallet .connect-btn {
    background: rgba(255, 255, 255, 0.01);
    border: 1px solid #FFFFFF;
    box-shadow: -7px 35px 25px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 205px;
    height: 51px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: .2s;
}
.connect-wallet .connect-btn:hover,
.logo-back-btn:hover{
    background: rgb(255 255 255 / 14%);
}

/* Minting Section */
.minting-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
}
.minting-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -105px;
}
.minting-container {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 20px;
    padding: 40px 33px;
    margin-top: 240px;
}
.minting-heading img {
    width: 401px;
    margin-bottom: 15px;
}
.minting-heading h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.minting-heading h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}
.mint-button-big .min-btn-img {
    background: #9C590A;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
}
.min-btn-img img {
    width: 90px;
    position: relative;
    left: -4px;
}
.min-btn-border {
    width: 111px;
    height: 111px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
    transition: .2s;
}
.mint-button-big {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 256px;
    transition: .2s;
    margin-left: 11px;
    padding: 20px;
}
.mint-button-big:hover {
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    background: #9C590A;
}
.mint-button-big:hover .min-btn-border {
    background: rgb(255 255 255 / 8%);
}
.minting-area.minting-area-details {
    width: 417px;
    background: rgb(0 0 0 / 20%);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    padding: 14px;
    margin-right: 11px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}
.minting-area-details .mint-desc {
    width: 180px;
    color: #000000;
    padding: 6px 20px;
    background: #FFFFFF;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
}
.minting-area-details .mint-desc ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    text-align: right;
    color: #000000;
}
.mint-fields ul li span {
    opacity: .3;
}
.minting-area-content {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 42px;
}
.mint-fields {
    padding-left: 20px;
    display: flex;
    align-items: center;
}
.mint-fields ul li {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 39px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}
.mint-fields select.form-field {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 39px;
    color: #FFFFFF;
    background: transparent;
    padding: 0 13px;
    outline: 0;
}
.mint-fields select.form-field * {
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    background-color: #16120a;
}
.mint-fields select{
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}
.mint-fields select::-ms-expand {
    display: none;
}
.contract-address {
    position: relative;
    margin-top: 40px;
    margin-bottom: 60px;
}
.contract-address h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}
.contract-address h4 a{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 8px;
    display: block;
    transition: .2s;
}
.contract-address h4 a:hover {
    color: #9C590A;
}
ul.social-icons li {
    display: flex;
    align-items: center;
    justify-content: center;
}
ul.social-icons li a{
    margin: 10px;
}
ul.social-icons li a.opensea-icon svg {
    color: #fff;
    width: 35px;
    transition: .2s;
}
ul.social-icons li a.telegram-icon {
    font-size: 35px;
    color: #fff;
    transition: .2s;
}
ul.social-icons li a.twitter-icon,
ul.social-icons li a.mail-icon,
ul.social-icons li a.discord-icon {
    color: #212020;
    background: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: .2s;
    font-size: 18px;
}
ul.social-icons li a.opensea-icon:hover svg{
    color: rgb(197 197 197);
}
ul.social-icons li a.telegram-icon:hover{
    color: rgb(197 197 197);
}
ul.social-icons li a.twitter-icon:hover,
ul.social-icons li a.mail-icon:hover,
ul.social-icons li a.discord-icon:hover {
    background: rgb(197 197 197);
}
.htp-link button {
    font-size: 17px;
    margin-top: 20px;
    display: block;
    text-align: center;
    line-height: 1;
    margin-bottom: -10px;
    text-transform: uppercase;
    color: #ff910f;
    transition: .2s;
    border: 0;
    width: 100%;
}
.htp-link button:hover{
    color: #9C590A;
}

/* Modal Close Button */
.modalCloseBtn {
    cursor: pointer;
    transition: .2s;
    font-size: 18px;
    line-height: 1;
    background: rgb(255 255 255 / 4%);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalCloseBtn:hover {
    background: rgb(255 255 255 / 15%);
}
/* Connect Wallet Modal */

.connectWalletModal .modal-dialog {
    max-width: 850px !important;
    width: 100%;
}
.connectWalletModal .modal-content {
    color: #ffffff;
    background-image: linear-gradient(rgb(0 0 0 / 75%), rgb(0 0 0 / 75%)), url(/static/media/colored-bg.82f72408.png);
    background-size: cover;
    background-position: center;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    border: 2px solid #ffffff8c;
}
.connectWalletModal .modal-content .modal-header {
    border: 0;
}
.connectWalletModal .modal-title {
    margin-bottom: 0;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
}
.conn-button-big{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.01);
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    width: 256px;
    transition: .2s;
    padding: 20px;
}
.conn-button-big:hover {
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
}
.conn-button-big.walletConnectBtn:hover {
    background: #217ffc;
}
.conn-button-big.coinbaseWalletBtn:hover {
    background: #1652f0;
}
.conn-button-big.metamaskIconBtn:hover {
    background: #763e1a;
}
.conn-btn-border {
    width: 111px;
    height: 111px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 5px;
    transition: .2s;
}
.conn-button-big .conn-btn-img {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 90px;
}
.conn-btn-img img {
    width: 90px;
    position: relative;
}
.connectWalletWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* How To Purchase Modal */
.howToPurchaseModal .modal-dialog {
    max-width: 1000px !important;
    width: 100%;
}
.howToPurchaseModal .modal-content {
    color: #ffffff;
    background-image: linear-gradient(rgb(0 0 0 / 75%), rgb(0 0 0 / 75%)), url(/static/media/colored-bg.82f72408.png);
    background-size: cover;
    background-position: center;
    box-shadow: -7px 35px 43px rgba(0, 0, 0, 0.28);
    border-radius: 10px;
    border: 2px solid #ffffff8c;
}
.howToPurchaseModal .modal-content .modal-header {
    border: 0;
}
.howToPurchaseModal .modal-title {
    margin-bottom: 0;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: left;
    text-transform: uppercase;
}
.video-content-details {
    padding-top: 30px;
    text-align: center;
}
.video-content-details h2 {
    margin-bottom: 10px;
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}
/* Video Content Style */
.video-content-wrapper .video-content{
    position: relative;
    padding-bottom: 62.5%;
    height: 0;
}
.video-content-wrapper .video-content iframe{
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}
@media only screen and (max-width: 1440px) {
    .main-section-body {
        height: 1150px;
    }
} /* Close 1440px */

@media only screen and (max-width: 1024px) {

} /* Close 1024px */

@media only screen and (max-width: 991px) {

} /* Close 991px */

@media only screen and (max-width: 768px) {
    .main-section-body {
        height: 1200px;
    }
    .minting-container {
        margin-top: 300px;
    }
    .minting-container {
        width: 100%;
    }
    .minting-heading img {
        width: 301px;
    }
    .connectWalletModal .modal-dialog {
        width: 95%;
    }
    .conn-button-big{
        width: 100%;
    }
    .connectWalletWrapper li{
        width: 32%;
    }

} /* Close 768px */

@media only screen and (max-width: 600px) {
    .minting-area-content {
        flex-direction: column;
    }
    .minting-area.minting-area-details {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .mint-button-big {
        width: 100%;
        margin: 0;
    }
    .minting-heading img {
        width: 220px;
    }
    .minting-heading h1 {
        font-size: 35px;
    }
    .minting-heading h4 {
        font-size: 20px;
    }
    .main-section-body {
        height: 100%;
    }
    .howToPurchaseModal .modal-dialog {
        width: 95%;
        margin: 30px auto;
    }
    .connectWalletWrapper li {
        width: 32%;
        margin-bottom: 20px;
    }
    .conn-btn-border {
        width: 90px;
        height: 90px;
    }
    .conn-button-big .conn-btn-img {
        width: 70px;
        height: 70px;
    }
    .conn-button-big {
        font-size: 16px;
    }
} /* Close 600px */

@media only screen and (max-width: 425px) {
    .bg-logo-overlay {
        width: 130px;
        height: 130px;
    }
    .logo-main img {
        width: 130px;
    }
    .connect-wallet .connect-btn {
        border-radius: 7px;
        width: 125px;
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .logo-wrapper {
        padding-left: 20px;
    }
    .contract-address h4 a {
        font-size: 14px;
        word-break: break-all;
    }
    .contract-address h3 {
        font-size: 18px;
    }
    .minting-area-details .mint-desc {
        padding: 10px;
    }
    .minting-area-details .mint-desc ul li {
        font-size: 14px;
        line-height: 35px;
    }
    .minting-container {
        padding: 15px;
    }
    .mint-fields ul li {
        font-size: 14px;
        line-height: 36px;
    }
    .mint-fields select.form-field {
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
    }
    .mint-button-big {
        font-weight: 600;
        font-size: 25px;
        line-height: 45px;
    }
    .video-content-details h2 {
        font-size: 16px;
    }
    .video-content-details p {
        font-size: 13px;
    }
    .howToPurchaseModal .modal-title {
        font-size: 18px;
    }
    .connectWalletWrapper li {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .connectWalletModal .modal-dialog {
        margin-top: 30px;
    }
} /* Close 425px */

@media only screen and (max-width: 375px) {

} /* Close 375px *

/* Styles for screens with a height of less than 500px */
@media (max-height: 1100px) {
    .main-section-body {
        height: 1200px;
    }
}
*,
:after,
:before {
	box-sizing: border-box;
	border: 0 solid #e5e7eb
}

:after,
:before {
	--tw-content: ""
}

html {
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	tab-size: 4;
	font-family: 'Roboto', sans-serif;
}

/* body {
	margin: 0;
	line-height: inherit;
	color: #fff
} */

body {
	background: url(/static/media/background.2c3d16fb.png) #000016;
	/* background-position: 50%; */
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	line-height: inherit;
}

hr {
	height: 0;
	color: inherit;
	border-top-width: 1px
}

abbr:where([title]) {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit
}

a {
	color: inherit;
	text-decoration: inherit
}

b,
strong {
	font-weight: bolder
}

code,
kbd,
pre,
samp {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
	font-size: 1em
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

table {
	text-indent: 0;
	border-color: inherit;
	border-collapse: collapse
}

.logo-style {
	height: 40px;
	width: 150px
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: inherit;
	color: inherit;
	margin: 0;
	padding: 0
}

button,
select {
	text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
	-webkit-appearance: button;
	background-color: transparent;
	background-image: none
}

:-moz-focusring {
	outline: auto
}

:-moz-ui-invalid {
	box-shadow: none
}

progress {
	vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto
}

[type=search] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}

summary {
	display: list-item
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
	margin: 0
}

fieldset {
	margin: 0
}

fieldset,
legend {
	padding: 0
}

menu,
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0
}

textarea {
	resize: vertical
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
	opacity: 1;
	color: #9ca3af
}

input::placeholder,
textarea::placeholder {
	opacity: 1;
	color: #9ca3af
}

[role=button],
button {
	cursor: pointer
}

:disabled {
	cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
	display: block;
	vertical-align: middle
}

img,
video {
	max-width: 100%;
	height: auto
}

[hidden] {
	display: none
}

*,
:after,
:before {
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x: ;
	--tw-pan-y: ;
	--tw-pinch-zoom: ;
	--tw-scroll-snap-strictness: proximity;
	--tw-ordinal: ;
	--tw-slashed-zero: ;
	--tw-numeric-figure: ;
	--tw-numeric-spacing: ;
	--tw-numeric-fraction: ;
	--tw-ring-inset: ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgb(59 130 246/0.5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	--tw-blur: ;
	--tw-brightness: ;
	--tw-contrast: ;
	--tw-grayscale: ;
	--tw-hue-rotate: ;
	--tw-invert: ;
	--tw-saturate: ;
	--tw-sepia: ;
	--tw-drop-shadow: ;
	--tw-backdrop-blur: ;
	--tw-backdrop-brightness: ;
	--tw-backdrop-contrast: ;
	--tw-backdrop-grayscale: ;
	--tw-backdrop-hue-rotate: ;
	--tw-backdrop-invert: ;
	--tw-backdrop-opacity: ;
	--tw-backdrop-saturate: ;
	--tw-backdrop-sepia:
}

.container {
	width: 100%
}

@media only screen and (max-width: 600px) {
	.logo-style {
		height: auto;
		width: 70%;
	}
}

@media (min-width:640px) {
	.container {
		max-width: 640px
	}

	.logo-style {
		height: auto;
		width: 23%;
	}
}

@media (min-width:768px) {
	.container {
		max-width: 768px
	}
}

@media (min-width:1024px) {
	.container {
		max-width: 1024px
	}
}

@media (min-width:1280px) {
	.container {
		max-width: 1280px
	}
}

@media (min-width:1536px) {
	.container {
		max-width: 1536px
	}
}

.absolute {
	position: absolute
}

.relative {
	position: relative
}

.inset-auto {
	top: auto;
	right: auto;
	bottom: auto;
	left: auto
}

.right-4 {
	right: 1rem
}

.top-2 {
	top: 0rem;
}

.left-2 {
	left: 13.9rem;
}

.z-10 {
	z-index: 10
}

.mx-auto {
	margin-left: auto;
	margin-right: auto
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem
}

.mint-img {
	margin-left: 20px;
}

.mt-16 {
	margin-top: 3rem
}

.ml-2 {
	margin-left: .5rem
}

.mt-0\.5 {
	margin-top: .125rem
}

.mt-0 {
	margin-top: 0
}

.mt-20 {
	margin-top: 5rem
}

.mt-14 {
	margin-top: 3.5rem
}

.mt-6 {
	margin-top: 1.5rem
}

.mt-3 {
	margin-top: .75rem
}

.mt-10 {
	margin-top: 2.5rem
}

.mt-12 {
	margin-top: 3rem
}

.mt-8 {
	margin-top: 2rem
}

.mt-4 {
	margin-top: 1rem
}

.block {
	display: block
}

.flex {
	display: flex
}

.inline-flex {
	display: inline-flex
}

.h-full {
	height: 100%
}

.h-6 {
	height: 1.5rem
}

.h-64 {
	height: 16rem
}

.h-10 {
	height: 2.5rem
}

.min-h-screen {
	min-height: 100vh
}

.w-full {
	width: 100%
}

.w-6 {
	width: 1.5rem
}

.w-64 {
	width: 16rem
}

.w-14 {
	width: 3.5rem
}

.min-w-full {
	min-width: 100%
}

.max-w-5xl {
	max-width: 90%;
}

.max-w-4xl {
	max-width: 56rem
}

.flex-1 {
	flex: 1 1
}

.grow {
	flex-grow: 1
}

@-webkit-keyframes pulse {
	50% {
		opacity: .5
	}
}

@keyframes pulse {
	50% {
		opacity: .5
	}
}

.animate-pulse-slow {
	-webkit-animation: pulse 10s linear infinite;
	animation: pulse 10s linear infinite
}

.cursor-pointer {
	cursor: pointer
}

.cursor-not-allowed {
	cursor: not-allowed
}

.flex-col {
	flex-direction: column
}

.items-center {
	align-items: center
}

.justify-center {
	justify-content: center
}

.justify-between {
	justify-content: space-between
}

.space-x-4>:not([hidden])~:not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1rem * var(--tw-space-x-reverse));
	margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-10>:not([hidden])~:not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: 0;
	margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}

.space-x-3>:not([hidden])~:not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: 0px;
}

.space-y-2>:not([hidden])~:not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
	overflow: hidden
}

.break-words {
	overflow-wrap: break-word
}

.break-all {
	word-break: break-all
}

.rounded {
	border-radius: .25rem
}

.rounded-md {
	border-radius: .375rem
}

.border-2 {
	border-width: 2px
}

.border {
	border-width: 1px
}

.border-t {
	border-top-width: 1px
}

.border-b {
	border-bottom-width: 1px
}

.border-\[rgba\(0\2c 0\2c 0\2c 1\)\] {
	border-color: #000
}

.border-brand-purple {
	border-color: #9b5de5;
	border-color: var(--clr-purple)
}

.border-green-500 {
	--tw-border-opacity: 1;
	border-color: rgb(34 197 94/var(--tw-border-opacity))
}

.border-gray-800 {
	--tw-border-opacity: 1;
	border-color: rgb(31 41 55/var(--tw-border-opacity))
}

/* .bg-brand-light {
	background-color: #000
} */

.bg-brand-background {
	background-color: #06070a;
	background-color: var(--clr-background)
}

.bg-gray-900\/90 {
	background-color: #282828a6;
	/* background-color: #8d5555a6; */
}

.bg-indigo-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.bg-black {
	--tw-bg-opacity: 1;
	background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.bg-gray-300 {
	--tw-bg-opacity: 1;
	background-color: rgb(209 213 219/var(--tw-bg-opacity))
}

.bg-gray-900 {
	--tw-bg-opacity: 1;
	background-color: rgb(17 24 39/var(--tw-bg-opacity))
}

.bg-gradient-to-br {
	background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.from-brand-blue {
	--tw-gradient-from: var(--clr-blue);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.from-brand-green {
	--tw-gradient-from: var(--clr-green);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.from-brand-purple {
	--tw-gradient-from: var(--clr-purple);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.to-brand-purple {
	--tw-gradient-to: var(--clr-purple)
}

.to-brand-blue {
	--tw-gradient-to: var(--clr-blue)
}

.to-brand-pink {
	--tw-gradient-to: var(--clr-pink)
}

.bg-clip-text {
	-webkit-background-clip: text;
	background-clip: text
}

.object-cover {
	object-fit: cover
}

.py-14 {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem
}

.px-4 {
	padding-left: 1rem;
	padding-right: 1rem
}

.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem
}

.py-10 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem
}

.px-2 {
	padding-left: .5rem;
	padding-right: .5rem
}

.py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem
}

.py-2 {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.py-3 {
	padding-top: .75rem;
	padding-bottom: .75rem
}

.pr-2 {
	padding-right: .5rem
}

.pt-4 {
	padding-top: 1rem
}

.text-center {
	text-align: center
}

.font-coiny {
	font-family: Coiny, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

.text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem
}

.text-sm {
	font-size: .875rem;
	line-height: 1.25rem
}

.text-2xl {
	font-size: 1.5rem;
	line-height: 2rem
}

.text-lg {
	font-size: 1.125rem;
	line-height: 1.75rem
}

.text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem
}

.text-base {
	font-size: 1rem;
	line-height: 1.5rem
}

.font-bold {
	font-weight: 700
}

.font-medium {
	font-weight: 500
}

.font-semibold {
	font-weight: 600
}

.uppercase {
	text-transform: uppercase
}

.tracking-wide {
	letter-spacing: .025em
}

.tracking-widest {
	letter-spacing: .1em
}

.text-gray-800 {
	--tw-text-opacity: 1;
	color: #fff
}

.text-transparent {
	color: transparent
}

.text-rose-500 {
	--tw-text-opacity: 1;
	/* color: #ce0014 */
}

.text-white {
	--tw-text-opacity: 1;
	color: rgb(255 255 255/var(--tw-text-opacity))
}

.text-pink-200 {
	--tw-text-opacity: 1;
	color: rgb(251 207 232/var(--tw-text-opacity))
}

.text-brand-pink {
	color: #ff8b01
}

.text-brand-background {
	color: #06070a;
	color: var(--clr-background)
}

.text-brand-yellow {
	color: #fee440;
	color: var(--clr-yellow)
}

.text-gray-400 {
	--tw-text-opacity: 1;
	color: rgb(156 163 175/var(--tw-text-opacity));
	font-weight: 600;
}

.opacity-80 {
	opacity: .8
}

.shadow-\[0px_3px_0px_0px_rgba\(0\2c 0\2c 0\2c 1\)\] {
	--tw-shadow: 0px 3px 0px 0px #000;
	--tw-shadow-colored: 0px 3px 0px 0px var(--tw-shadow-color)
}

.shadow-\[0px_3px_0px_0px_rgba\(0\2c 0\2c 0\2c 1\)\],
.shadow-lg {
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
}

.filter {
	-webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
	        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-sm {
	--tw-backdrop-blur: blur(13px);
}

.backdrop-blur-lg,
.backdrop-blur-sm {
	-webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-lg {
	--tw-backdrop-blur: blur(16px)
}

.transition {
	transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	transition-duration: .15s
}

.duration-200 {
	transition-duration: .2s
}

.ease-in-out {
	transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

:root {
	--font-family: "Open Sans", sans-serif;
	--clr-light: #f0e9e9;
	--clr-purple: #9b5de5;
	--clr-pink: #f15bb5;
	--clr-yellow: #fee440;
	--clr-blue: #00bbf9;
	--clr-green: #00f5d4;
	--clr-selection-bg: #cb3d92;
	--clr-selection-text: #f4f0f0;
	--clr-background: #06070a;
	--onboard-white: #fff;
	--onboard-black: #000;
	--onboard-primary-1: #2f80ed;
	--onboard-primary-100: #eff1fc;
	--onboard-primary-200: #d0d4f7;
	--onboard-primary-300: #b1b8f2;
	--onboard-primary-400: #929bed;
	--onboard-primary-500: #6370e5;
	--onboard-primary-600: #454ea0;
	--onboard-primary-700: #323873;
	--onboard-gray-100: #ebebed;
	--onboard-gray-200: #c2c4c9;
	--onboard-gray-300: #999ca5;
	--onboard-gray-400: #707481;
	--onboard-gray-500: #33394b;
	--onboard-gray-600: #242835;
	--onboard-gray-700: #1a1d26;
	--onboard-success-100: #d1fae3;
	--onboard-success-200: #baf7d5;
	--onboard-success-300: #a4f4c6;
	--onboard-success-400: #8df2b8;
	--onboard-success-500: #5aec99;
	--onboard-success-600: #18ce66;
	--onboard-success-700: #129b4d;
	--onboard-danger-100: #ffe5e6;
	--onboard-danger-200: #fcc;
	--onboard-danger-300: #ffb3b3;
	--onboard-danger-400: #ff8080;
	--onboard-danger-500: #ff4f4f;
	--onboard-danger-600: #c00;
	--onboard-danger-700: #600;
	--onboard-warning-100: #ffefcc;
	--onboard-warning-200: #ffe7b3;
	--onboard-warning-300: #ffd780;
	--onboard-warning-400: #ffc74c;
	--onboard-warning-500: #ffaf00;
	--onboard-warning-600: #cc8c00;
	--onboard-warning-700: #664600
}

html {
	scroll-behavior: smooth;
	font-family: "Open Sans", sans-serif;
	font-family: var(--font-family);
	background: #06070a;
	background: var(--clr-background)
}

::selection {
	background: #cb3d92;
	background: var(--clr-selection-bg);
	color: #f4f0f0;
	color: var(--clr-selection-text)
}

.hover\:bg-rose-600:hover {
	--tw-bg-opacity: 1;
	color: #e9a41c;
	/* padding: 8px; */
}

/* .hover\:text-white:hover {
	--tw-text-opacity: 1;
	color: rgb(255 255 255/var(--tw-text-opacity))
} */

.hover\:shadow-lg:hover {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-pink-400\/50:hover {
	--tw-shadow-color: rgb(244 114 182/0.5);
	--tw-shadow: var(--tw-shadow-colored)
}

.active\:shadow-none:active {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width:640px) {
	.sm\:h-\[280px\] {
		height: 295px
	}

	.sm\:text-2xl {
		font-size: 1.5rem;
		line-height: 2rem
	}
}

@media (min-width:768px) {
	.md\:mt-14 {
		margin-top: 3.5rem
	}

	.md\:mt-0 {
		margin-top: 0
	}

	.md\:mt-4 {
		margin-top: 1rem
	}

	.md\:h-8 {
		height: 2rem
	}

	.md\:h-12 {
		height: 3rem
	}

	.md\:w-8 {
		width: 2rem
	}

	.md\:w-\[250px\] {
		width: 294px
	}

	.md\:w-16 {
		width: 4rem
	}

	.md\:max-w-3xl {
		max-width: 48rem
	}

	.md\:flex-row {
		flex-direction: row
	}

	.md\:items-start {
		align-items: flex-start
	}

	.md\:space-x-6>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(1.5rem * var(--tw-space-x-reverse));
		margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
	}

	.md\:space-x-16>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
		margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
	}

	.md\:space-x-14>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(3.5rem * var(--tw-space-x-reverse));
		/* margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) */
	}

	.md\:px-0 {
		padding-left: 0;
		padding-right: 0
	}

	.md\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem
	}

	.md\:text-3xl {
		font-size: 1.875rem;
		line-height: 2.25rem
	}

	.md\:text-4xl {
		font-size: 2.25rem;
		line-height: 2.5rem
	}

	.md\:text-base {
		font-size: 1rem;
		line-height: 1.5rem
	}
}

.wallets-container.svelte-11upx36 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-columns: repeat(var(--onboard-wallet-columns, 2), 1fr);
	padding: 10px;
	width: 100%;
}

button.svelte-ucnmri {
	border: 1px solid #ffac27;
	transition: background-color 250ms ease-in-out;
	color: #fff;
	background: linear-gradient(114.86deg, #FF8A00 18.07%, #FFCD4D 122.57%);
}

.modal-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border-radius: 24px;
	cursor: pointer;
	font: inherit;
	border: none;
	transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.relative {
	position: relative;
}

.justify-start {
	justify-content: flex-start;
}

.icon.svelte-1wcty06 {
	height: 100%;
}

.justify-center {
	justify-content: center;
}

.background-transparent.svelte-1wcty06 {
	background: transparent;
}

.items-center {
	align-items: center;
}

.flex {
	display: flex;
}

.name.svelte-ucnmri {
	margin-left: 4px;
}

.card-content>.card {
	margin: 1%;
	width: 48%;
	border-radius: 7px;
	box-sizing: border-box;
	text-align: center;
	text-transform: uppercase;
	padding: 23px 0;
	float: left;
	color: #fff;
	background: transparent;
	transition: .5s;
	-webkit-transition: .5s;
	cursor: pointer;
	box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 11%);
	border: 1px solid #fff;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	/* background-color: #000; */
	background-clip: border-box;
	border: none;
	/* border-radius: 0.25rem; */
	margin: 10px;
}

.btn-close {
	box-sizing: content-box;
	width: 0.5em;
	height: 0.5em;
	padding: 0.25em 0.25em;
	color: #000000;
	background: transparent url(/static/media/close.0865c053.svg) center/2em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.modal-btn:hover {
	background: linear-gradient(114.86deg, #FFCD4D 18.07%, #FF8A00 122.57%);
	color: #FFF;
}

.modal-title {
	margin-bottom: 0;
	/* color: #000; */
}

.mint-button {
	box-sizing: border-box;
	background-color: transparent;
	border-radius: 0.6em;
	color: #e74c3c;
	cursor: pointer;
	align-self: center;
	font-weight: 400;
	line-height: 1;
	margin: 20px;
	padding: 1em 0.4em;
	text-transform: uppercase;
	font-weight: 600;
	width: 100%;

}

.mint-button:hover,
.mint-button:focus {
	color: #fff;
	outline: 0;
}

.third {
	border-color: #e9a41c;
	color: #fff;
	box-shadow: 0 0 40px 40px #514a4a85 inset, 0 0 0 0 #e7000a40;
	transition: all 150ms ease-in-out;
}

.third:hover {
	box-shadow: 0 0 10px 0 #e9a41c inset, 0 0 10px 4px #e9a41c;
}

.connect-btn {
	max-width: 200px;
	width: 172px;
	margin: 0px;
}

@media only screen and (max-width: 600px) {
	.mint-button {
		padding: 13px;
		font-weight: 500;
	}

	.connect-btn {
		max-width: 150px;
		width: 150px;
	}

	.left-2 {
		left: 15rem;
		padding: 10px;
	}

	.mint-img {
		margin-left: 0px;
	}

	.home-nft {
		width: 100%;
		height: 100%;
		padding-top: 20px;
	}
}

.social-links {
	display: flex;
}

.social-links a {
	/* width: 80px; */
	/* height: 80px; */
	text-align: center;
	/* text-decoration: none; */
	/* color: #000; */
	/* box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05); */
	margin-right: 30px;
	margin-left: 30px;
	margin-bottom: 30px;
	/* border-radius: 50%; */
	position: relative;
	/* overflow: hidden; */
	/* transition: transform 0.5s; */
}

.modal-content {
	background-color: #fff;
	color: #414141;
}

.main-color {
	color: #e9a41c;
}

.select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	background-color: #16120a;
}

.select-dropdown {
	position: relative;
	background-color: transparent;
	border-radius: 4px;
}

.select-dropdown select {
	font-size: 1rem;
	font-weight: normal;
	max-width: 100%;
	padding: 5px 22px 4px 6px;
	border: none;
	background-color: transparent;
	-webkit-appearance: none;
	appearance: none;
	min-width: 100px;
}

.select-dropdown select:active,
.select-dropdown select:focus {
	outline: none;
	box-shadow: none;
}

.select-dropdown:after {
	content: "";
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0;
	height: 0;
	margin-top: -2px;
	border-top: 5px solid #aaa;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
