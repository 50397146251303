*,
:after,
:before {
	box-sizing: border-box;
	border: 0 solid #e5e7eb
}

:after,
:before {
	--tw-content: ""
}

html {
	line-height: 1.5;
	-webkit-text-size-adjust: 100%;
	-moz-tab-size: 4;
	-o-tab-size: 4;
	tab-size: 4;
	font-family: 'Roboto', sans-serif;
}

/* body {
	margin: 0;
	line-height: inherit;
	color: #fff
} */

body {
	background: url('../img/background.png') #000016;
	/* background-position: 50%; */
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	line-height: inherit;
}

hr {
	height: 0;
	color: inherit;
	border-top-width: 1px
}

abbr:where([title]) {
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit
}

a {
	color: inherit;
	text-decoration: inherit
}

b,
strong {
	font-weight: bolder
}

code,
kbd,
pre,
samp {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
	font-size: 1em
}

small {
	font-size: 80%
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}

sub {
	bottom: -.25em
}

sup {
	top: -.5em
}

table {
	text-indent: 0;
	border-color: inherit;
	border-collapse: collapse
}

.logo-style {
	height: 40px;
	width: 150px
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: inherit;
	color: inherit;
	margin: 0;
	padding: 0
}

button,
select {
	text-transform: none
}

[type=button],
[type=reset],
[type=submit],
button {
	-webkit-appearance: button;
	background-color: transparent;
	background-image: none
}

:-moz-focusring {
	outline: auto
}

:-moz-ui-invalid {
	box-shadow: none
}

progress {
	vertical-align: baseline
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto
}

[type=search] {
	-webkit-appearance: textfield;
	outline-offset: -2px
}

::-webkit-search-decoration {
	-webkit-appearance: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit
}

summary {
	display: list-item
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
	margin: 0
}

fieldset {
	margin: 0
}

fieldset,
legend {
	padding: 0
}

menu,
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0
}

textarea {
	resize: vertical
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	opacity: 1;
	color: #9ca3af
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	opacity: 1;
	color: #9ca3af
}

input::placeholder,
textarea::placeholder {
	opacity: 1;
	color: #9ca3af
}

[role=button],
button {
	cursor: pointer
}

:disabled {
	cursor: default
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
	display: block;
	vertical-align: middle
}

img,
video {
	max-width: 100%;
	height: auto
}

[hidden] {
	display: none
}

*,
:after,
:before {
	--tw-translate-x: 0;
	--tw-translate-y: 0;
	--tw-rotate: 0;
	--tw-skew-x: 0;
	--tw-skew-y: 0;
	--tw-scale-x: 1;
	--tw-scale-y: 1;
	--tw-pan-x: ;
	--tw-pan-y: ;
	--tw-pinch-zoom: ;
	--tw-scroll-snap-strictness: proximity;
	--tw-ordinal: ;
	--tw-slashed-zero: ;
	--tw-numeric-figure: ;
	--tw-numeric-spacing: ;
	--tw-numeric-fraction: ;
	--tw-ring-inset: ;
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgb(59 130 246/0.5);
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	--tw-blur: ;
	--tw-brightness: ;
	--tw-contrast: ;
	--tw-grayscale: ;
	--tw-hue-rotate: ;
	--tw-invert: ;
	--tw-saturate: ;
	--tw-sepia: ;
	--tw-drop-shadow: ;
	--tw-backdrop-blur: ;
	--tw-backdrop-brightness: ;
	--tw-backdrop-contrast: ;
	--tw-backdrop-grayscale: ;
	--tw-backdrop-hue-rotate: ;
	--tw-backdrop-invert: ;
	--tw-backdrop-opacity: ;
	--tw-backdrop-saturate: ;
	--tw-backdrop-sepia:
}

.container {
	width: 100%
}

@media only screen and (max-width: 600px) {
	.logo-style {
		height: auto;
		width: 70%;
	}
}

@media (min-width:640px) {
	.container {
		max-width: 640px
	}

	.logo-style {
		height: auto;
		width: 23%;
	}
}

@media (min-width:768px) {
	.container {
		max-width: 768px
	}
}

@media (min-width:1024px) {
	.container {
		max-width: 1024px
	}
}

@media (min-width:1280px) {
	.container {
		max-width: 1280px
	}
}

@media (min-width:1536px) {
	.container {
		max-width: 1536px
	}
}

.absolute {
	position: absolute
}

.relative {
	position: relative
}

.inset-auto {
	top: auto;
	right: auto;
	bottom: auto;
	left: auto
}

.right-4 {
	right: 1rem
}

.top-2 {
	top: 0rem;
}

.left-2 {
	left: 13.9rem;
}

.z-10 {
	z-index: 10
}

.mx-auto {
	margin-left: auto;
	margin-right: auto
}

.mx-4 {
	margin-left: 1rem;
	margin-right: 1rem
}

.mint-img {
	margin-left: 20px;
}

.mt-16 {
	margin-top: 3rem
}

.ml-2 {
	margin-left: .5rem
}

.mt-0\.5 {
	margin-top: .125rem
}

.mt-0 {
	margin-top: 0
}

.mt-20 {
	margin-top: 5rem
}

.mt-14 {
	margin-top: 3.5rem
}

.mt-6 {
	margin-top: 1.5rem
}

.mt-3 {
	margin-top: .75rem
}

.mt-10 {
	margin-top: 2.5rem
}

.mt-12 {
	margin-top: 3rem
}

.mt-8 {
	margin-top: 2rem
}

.mt-4 {
	margin-top: 1rem
}

.block {
	display: block
}

.flex {
	display: flex
}

.inline-flex {
	display: inline-flex
}

.h-full {
	height: 100%
}

.h-6 {
	height: 1.5rem
}

.h-64 {
	height: 16rem
}

.h-10 {
	height: 2.5rem
}

.min-h-screen {
	min-height: 100vh
}

.w-full {
	width: 100%
}

.w-6 {
	width: 1.5rem
}

.w-64 {
	width: 16rem
}

.w-14 {
	width: 3.5rem
}

.min-w-full {
	min-width: 100%
}

.max-w-5xl {
	max-width: 90%;
}

.max-w-4xl {
	max-width: 56rem
}

.flex-1 {
	flex: 1 1 0%
}

.grow {
	flex-grow: 1
}

@-webkit-keyframes pulse {
	50% {
		opacity: .5
	}
}

@keyframes pulse {
	50% {
		opacity: .5
	}
}

.animate-pulse-slow {
	-webkit-animation: pulse 10s linear infinite;
	animation: pulse 10s linear infinite
}

.cursor-pointer {
	cursor: pointer
}

.cursor-not-allowed {
	cursor: not-allowed
}

.flex-col {
	flex-direction: column
}

.items-center {
	align-items: center
}

.justify-center {
	justify-content: center
}

.justify-between {
	justify-content: space-between
}

.space-x-4>:not([hidden])~:not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: calc(1rem * var(--tw-space-x-reverse));
	margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.space-y-10>:not([hidden])~:not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: 0;
	margin-bottom: calc(2.5rem * var(--tw-space-y-reverse))
}

.space-x-3>:not([hidden])~:not([hidden]) {
	--tw-space-x-reverse: 0;
	margin-right: 0px;
}

.space-y-2>:not([hidden])~:not([hidden]) {
	--tw-space-y-reverse: 0;
	margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
	margin-bottom: calc(.5rem * var(--tw-space-y-reverse))
}

.overflow-hidden {
	overflow: hidden
}

.break-words {
	overflow-wrap: break-word
}

.break-all {
	word-break: break-all
}

.rounded {
	border-radius: .25rem
}

.rounded-md {
	border-radius: .375rem
}

.border-2 {
	border-width: 2px
}

.border {
	border-width: 1px
}

.border-t {
	border-top-width: 1px
}

.border-b {
	border-bottom-width: 1px
}

.border-\[rgba\(0\2c 0\2c 0\2c 1\)\] {
	border-color: #000
}

.border-brand-purple {
	border-color: var(--clr-purple)
}

.border-green-500 {
	--tw-border-opacity: 1;
	border-color: rgb(34 197 94/var(--tw-border-opacity))
}

.border-gray-800 {
	--tw-border-opacity: 1;
	border-color: rgb(31 41 55/var(--tw-border-opacity))
}

/* .bg-brand-light {
	background-color: #000
} */

.bg-brand-background {
	background-color: var(--clr-background)
}

.bg-gray-900\/90 {
	background-color: #282828a6;
	/* background-color: #8d5555a6; */
}

.bg-indigo-600 {
	--tw-bg-opacity: 1;
	background-color: rgb(79 70 229/var(--tw-bg-opacity))
}

.bg-black {
	--tw-bg-opacity: 1;
	background-color: rgb(0 0 0/var(--tw-bg-opacity))
}

.bg-gray-300 {
	--tw-bg-opacity: 1;
	background-color: rgb(209 213 219/var(--tw-bg-opacity))
}

.bg-gray-900 {
	--tw-bg-opacity: 1;
	background-color: rgb(17 24 39/var(--tw-bg-opacity))
}

.bg-gradient-to-br {
	background-image: linear-gradient(to bottom right, var(--tw-gradient-stops))
}

.from-brand-blue {
	--tw-gradient-from: var(--clr-blue);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.from-brand-green {
	--tw-gradient-from: var(--clr-green);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.from-brand-purple {
	--tw-gradient-from: var(--clr-purple);
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255/0))
}

.to-brand-purple {
	--tw-gradient-to: var(--clr-purple)
}

.to-brand-blue {
	--tw-gradient-to: var(--clr-blue)
}

.to-brand-pink {
	--tw-gradient-to: var(--clr-pink)
}

.bg-clip-text {
	-webkit-background-clip: text;
	background-clip: text
}

.object-cover {
	-o-object-fit: cover;
	object-fit: cover
}

.py-14 {
	padding-top: 3.5rem;
	padding-bottom: 3.5rem
}

.px-4 {
	padding-left: 1rem;
	padding-right: 1rem
}

.px-6 {
	padding-left: 1.5rem;
	padding-right: 1.5rem
}

.py-10 {
	padding-top: 2.5rem;
	padding-bottom: 2.5rem
}

.px-2 {
	padding-left: .5rem;
	padding-right: .5rem
}

.py-4 {
	padding-top: 1rem;
	padding-bottom: 1rem
}

.py-2 {
	padding-top: .5rem;
	padding-bottom: .5rem
}

.py-3 {
	padding-top: .75rem;
	padding-bottom: .75rem
}

.pr-2 {
	padding-right: .5rem
}

.pt-4 {
	padding-top: 1rem
}

.text-center {
	text-align: center
}

.font-coiny {
	font-family: Coiny, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji
}

.text-xl {
	font-size: 1.25rem;
	line-height: 1.75rem
}

.text-sm {
	font-size: .875rem;
	line-height: 1.25rem
}

.text-2xl {
	font-size: 1.5rem;
	line-height: 2rem
}

.text-lg {
	font-size: 1.125rem;
	line-height: 1.75rem
}

.text-3xl {
	font-size: 1.875rem;
	line-height: 2.25rem
}

.text-base {
	font-size: 1rem;
	line-height: 1.5rem
}

.font-bold {
	font-weight: 700
}

.font-medium {
	font-weight: 500
}

.font-semibold {
	font-weight: 600
}

.uppercase {
	text-transform: uppercase
}

.tracking-wide {
	letter-spacing: .025em
}

.tracking-widest {
	letter-spacing: .1em
}

.text-gray-800 {
	--tw-text-opacity: 1;
	color: #fff
}

.text-transparent {
	color: transparent
}

.text-rose-500 {
	--tw-text-opacity: 1;
	/* color: #ce0014 */
}

.text-white {
	--tw-text-opacity: 1;
	color: rgb(255 255 255/var(--tw-text-opacity))
}

.text-pink-200 {
	--tw-text-opacity: 1;
	color: rgb(251 207 232/var(--tw-text-opacity))
}

.text-brand-pink {
	color: #ff8b01
}

.text-brand-background {
	color: var(--clr-background)
}

.text-brand-yellow {
	color: var(--clr-yellow)
}

.text-gray-400 {
	--tw-text-opacity: 1;
	color: rgb(156 163 175/var(--tw-text-opacity));
	font-weight: 600;
}

.opacity-80 {
	opacity: .8
}

.shadow-\[0px_3px_0px_0px_rgba\(0\2c 0\2c 0\2c 1\)\] {
	--tw-shadow: 0px 3px 0px 0px #000;
	--tw-shadow-colored: 0px 3px 0px 0px var(--tw-shadow-color)
}

.shadow-\[0px_3px_0px_0px_rgba\(0\2c 0\2c 0\2c 1\)\],
.shadow-lg {
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.shadow-lg {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color)
}

.filter {
	filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.backdrop-blur-sm {
	--tw-backdrop-blur: blur(13px);
}

.backdrop-blur-lg,
.backdrop-blur-sm {
	-webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
	backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia)
}

.backdrop-blur-lg {
	--tw-backdrop-blur: blur(16px)
}

.transition {
	transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
	transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
	transition-timing-function: cubic-bezier(.4, 0, .2, 1);
	transition-duration: .15s
}

.duration-200 {
	transition-duration: .2s
}

.ease-in-out {
	transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

:root {
	--font-family: "Open Sans", sans-serif;
	--clr-light: #f0e9e9;
	--clr-purple: #9b5de5;
	--clr-pink: #f15bb5;
	--clr-yellow: #fee440;
	--clr-blue: #00bbf9;
	--clr-green: #00f5d4;
	--clr-selection-bg: #cb3d92;
	--clr-selection-text: #f4f0f0;
	--clr-background: #06070a;
	--onboard-white: #fff;
	--onboard-black: #000;
	--onboard-primary-1: #2f80ed;
	--onboard-primary-100: #eff1fc;
	--onboard-primary-200: #d0d4f7;
	--onboard-primary-300: #b1b8f2;
	--onboard-primary-400: #929bed;
	--onboard-primary-500: #6370e5;
	--onboard-primary-600: #454ea0;
	--onboard-primary-700: #323873;
	--onboard-gray-100: #ebebed;
	--onboard-gray-200: #c2c4c9;
	--onboard-gray-300: #999ca5;
	--onboard-gray-400: #707481;
	--onboard-gray-500: #33394b;
	--onboard-gray-600: #242835;
	--onboard-gray-700: #1a1d26;
	--onboard-success-100: #d1fae3;
	--onboard-success-200: #baf7d5;
	--onboard-success-300: #a4f4c6;
	--onboard-success-400: #8df2b8;
	--onboard-success-500: #5aec99;
	--onboard-success-600: #18ce66;
	--onboard-success-700: #129b4d;
	--onboard-danger-100: #ffe5e6;
	--onboard-danger-200: #fcc;
	--onboard-danger-300: #ffb3b3;
	--onboard-danger-400: #ff8080;
	--onboard-danger-500: #ff4f4f;
	--onboard-danger-600: #c00;
	--onboard-danger-700: #600;
	--onboard-warning-100: #ffefcc;
	--onboard-warning-200: #ffe7b3;
	--onboard-warning-300: #ffd780;
	--onboard-warning-400: #ffc74c;
	--onboard-warning-500: #ffaf00;
	--onboard-warning-600: #cc8c00;
	--onboard-warning-700: #664600
}

html {
	scroll-behavior: smooth;
	font-family: var(--font-family);
	background: var(--clr-background)
}

::-moz-selection {
	background: var(--clr-selection-bg);
	color: var(--clr-selection-text)
}

::selection {
	background: var(--clr-selection-bg);
	color: var(--clr-selection-text)
}

.hover\:bg-rose-600:hover {
	--tw-bg-opacity: 1;
	color: #e9a41c;
	/* padding: 8px; */
}

/* .hover\:text-white:hover {
	--tw-text-opacity: 1;
	color: rgb(255 255 255/var(--tw-text-opacity))
} */

.hover\:shadow-lg:hover {
	--tw-shadow: 0 10px 15px -3px rgb(0 0 0/0.1), 0 4px 6px -4px rgb(0 0 0/0.1);
	--tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.hover\:shadow-pink-400\/50:hover {
	--tw-shadow-color: rgb(244 114 182/0.5);
	--tw-shadow: var(--tw-shadow-colored)
}

.active\:shadow-none:active {
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

@media (min-width:640px) {
	.sm\:h-\[280px\] {
		height: 295px
	}

	.sm\:text-2xl {
		font-size: 1.5rem;
		line-height: 2rem
	}
}

@media (min-width:768px) {
	.md\:mt-14 {
		margin-top: 3.5rem
	}

	.md\:mt-0 {
		margin-top: 0
	}

	.md\:mt-4 {
		margin-top: 1rem
	}

	.md\:h-8 {
		height: 2rem
	}

	.md\:h-12 {
		height: 3rem
	}

	.md\:w-8 {
		width: 2rem
	}

	.md\:w-\[250px\] {
		width: 294px
	}

	.md\:w-16 {
		width: 4rem
	}

	.md\:max-w-3xl {
		max-width: 48rem
	}

	.md\:flex-row {
		flex-direction: row
	}

	.md\:items-start {
		align-items: flex-start
	}

	.md\:space-x-6>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(1.5rem * var(--tw-space-x-reverse));
		margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)))
	}

	.md\:space-x-16>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
		margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)))
	}

	.md\:space-x-14>:not([hidden])~:not([hidden]) {
		--tw-space-x-reverse: 0;
		margin-right: calc(3.5rem * var(--tw-space-x-reverse));
		/* margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) */
	}

	.md\:px-0 {
		padding-left: 0;
		padding-right: 0
	}

	.md\:px-10 {
		padding-left: 2.5rem;
		padding-right: 2.5rem
	}

	.md\:text-3xl {
		font-size: 1.875rem;
		line-height: 2.25rem
	}

	.md\:text-4xl {
		font-size: 2.25rem;
		line-height: 2.5rem
	}

	.md\:text-base {
		font-size: 1rem;
		line-height: 1.5rem
	}
}

.wallets-container.svelte-11upx36 {
	display: grid;
	grid-template-columns: repeat(var(--onboard-wallet-columns, 2), 1fr);
	padding: 10px;
	width: 100%;
}

button.svelte-ucnmri {
	border: 1px solid #ffac27;
	transition: background-color 250ms ease-in-out;
	color: #fff;
	background: linear-gradient(114.86deg, #FF8A00 18.07%, #FFCD4D 122.57%);
}

.modal-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border-radius: 24px;
	cursor: pointer;
	font: inherit;
	border: none;
	transition: background-color .15s ease-in-out, color .15s ease-in-out;
}

.relative {
	position: relative;
}

.justify-start {
	justify-content: flex-start;
}

.icon.svelte-1wcty06 {
	height: 100%;
}

.justify-center {
	justify-content: center;
}

.background-transparent.svelte-1wcty06 {
	background: transparent;
}

.items-center {
	align-items: center;
}

.flex {
	display: flex;
}

.name.svelte-ucnmri {
	margin-left: 4px;
}

.card-content>.card {
	margin: 1%;
	width: 48%;
	border-radius: 7px;
	box-sizing: border-box;
	text-align: center;
	text-transform: uppercase;
	padding: 23px 0;
	float: left;
	color: #fff;
	background: transparent;
	transition: .5s;
	-webkit-transition: .5s;
	cursor: pointer;
	box-shadow: 10px 10px 5px 0 rgb(0 0 0 / 11%);
	border: 1px solid #fff;
}

.card {
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	/* background-color: #000; */
	background-clip: border-box;
	border: none;
	/* border-radius: 0.25rem; */
	margin: 10px;
}

.btn-close {
	box-sizing: content-box;
	width: 0.5em;
	height: 0.5em;
	padding: 0.25em 0.25em;
	color: #000000;
	background: transparent url(../img/close.svg) center/2em auto no-repeat;
	border: 0;
	border-radius: 0.25rem;
	opacity: 0.5;
}

.modal-btn:hover {
	background: linear-gradient(114.86deg, #FFCD4D 18.07%, #FF8A00 122.57%);
	color: #FFF;
}

.modal-title {
	margin-bottom: 0;
	/* color: #000; */
}

.mint-button {
	box-sizing: border-box;
	background-color: transparent;
	border-radius: 0.6em;
	color: #e74c3c;
	cursor: pointer;
	align-self: center;
	font-weight: 400;
	line-height: 1;
	margin: 20px;
	padding: 1em 0.4em;
	text-transform: uppercase;
	font-weight: 600;
	width: 100%;

}

.mint-button:hover,
.mint-button:focus {
	color: #fff;
	outline: 0;
}

.third {
	border-color: #e9a41c;
	color: #fff;
	box-shadow: 0 0 40px 40px #514a4a85 inset, 0 0 0 0 #e7000a40;
	transition: all 150ms ease-in-out;
}

.third:hover {
	box-shadow: 0 0 10px 0 #e9a41c inset, 0 0 10px 4px #e9a41c;
}

.connect-btn {
	max-width: 200px;
	width: 172px;
	margin: 0px;
}

@media only screen and (max-width: 600px) {
	.mint-button {
		padding: 13px;
		font-weight: 500;
	}

	.connect-btn {
		max-width: 150px;
		width: 150px;
	}

	.left-2 {
		left: 15rem;
		padding: 10px;
	}

	.mint-img {
		margin-left: 0px;
	}

	.home-nft {
		width: 100%;
		height: 100%;
		padding-top: 20px;
	}
}

.social-links {
	display: flex;
}

.social-links a {
	/* width: 80px; */
	/* height: 80px; */
	text-align: center;
	/* text-decoration: none; */
	/* color: #000; */
	/* box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05); */
	margin-right: 30px;
	margin-left: 30px;
	margin-bottom: 30px;
	/* border-radius: 50%; */
	position: relative;
	/* overflow: hidden; */
	/* transition: transform 0.5s; */
}

.modal-content {
	background-color: #fff;
	color: #414141;
}

.main-color {
	color: #e9a41c;
}

.select-dropdown,
.select-dropdown * {
	margin: 0;
	padding: 0;
	position: relative;
	box-sizing: border-box;
	background-color: #16120a;
}

.select-dropdown {
	position: relative;
	background-color: transparent;
	border-radius: 4px;
}

.select-dropdown select {
	font-size: 1rem;
	font-weight: normal;
	max-width: 100%;
	padding: 5px 22px 4px 6px;
	border: none;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	min-width: 100px;
}

.select-dropdown select:active,
.select-dropdown select:focus {
	outline: none;
	box-shadow: none;
}

.select-dropdown:after {
	content: "";
	position: absolute;
	top: 50%;
	right: 8px;
	width: 0;
	height: 0;
	margin-top: -2px;
	border-top: 5px solid #aaa;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}