@media only screen and (max-width: 1440px) {
    .main-section-body {
        height: 1150px;
    }
} /* Close 1440px */

@media only screen and (max-width: 1024px) {

} /* Close 1024px */

@media only screen and (max-width: 991px) {

} /* Close 991px */

@media only screen and (max-width: 768px) {
    .main-section-body {
        height: 1200px;
    }
    .minting-container {
        margin-top: 300px;
    }
    .minting-container {
        width: 100%;
    }
    .minting-heading img {
        width: 301px;
    }
    .connectWalletModal .modal-dialog {
        width: 95%;
    }
    .conn-button-big{
        width: 100%;
    }
    .connectWalletWrapper li{
        width: 32%;
    }

} /* Close 768px */

@media only screen and (max-width: 600px) {
    .minting-area-content {
        flex-direction: column;
    }
    .minting-area.minting-area-details {
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
    }
    .mint-button-big {
        width: 100%;
        margin: 0;
    }
    .minting-heading img {
        width: 220px;
    }
    .minting-heading h1 {
        font-size: 35px;
    }
    .minting-heading h4 {
        font-size: 20px;
    }
    .main-section-body {
        height: 100%;
    }
    .howToPurchaseModal .modal-dialog {
        width: 95%;
        margin: 30px auto;
    }
    .connectWalletWrapper li {
        width: 32%;
        margin-bottom: 20px;
    }
    .conn-btn-border {
        width: 90px;
        height: 90px;
    }
    .conn-button-big .conn-btn-img {
        width: 70px;
        height: 70px;
    }
    .conn-button-big {
        font-size: 16px;
    }
} /* Close 600px */

@media only screen and (max-width: 425px) {
    .bg-logo-overlay {
        width: 130px;
        height: 130px;
    }
    .logo-main img {
        width: 130px;
    }
    .connect-wallet .connect-btn {
        border-radius: 7px;
        width: 125px;
        height: 50px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .logo-wrapper {
        padding-left: 20px;
    }
    .contract-address h4 a {
        font-size: 14px;
        word-break: break-all;
    }
    .contract-address h3 {
        font-size: 18px;
    }
    .minting-area-details .mint-desc {
        padding: 10px;
    }
    .minting-area-details .mint-desc ul li {
        font-size: 14px;
        line-height: 35px;
    }
    .minting-container {
        padding: 15px;
    }
    .mint-fields ul li {
        font-size: 14px;
        line-height: 36px;
    }
    .mint-fields select.form-field {
        font-weight: 500;
        font-size: 14px;
        line-height: 1;
    }
    .mint-button-big {
        font-weight: 600;
        font-size: 25px;
        line-height: 45px;
    }
    .video-content-details h2 {
        font-size: 16px;
    }
    .video-content-details p {
        font-size: 13px;
    }
    .howToPurchaseModal .modal-title {
        font-size: 18px;
    }
    .connectWalletWrapper li {
        width: 70%;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .connectWalletModal .modal-dialog {
        margin-top: 30px;
    }
} /* Close 425px */

@media only screen and (max-width: 375px) {

} /* Close 375px *

/* Styles for screens with a height of less than 500px */
@media (max-height: 1100px) {
    .main-section-body {
        height: 1200px;
    }
}